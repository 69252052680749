<template>
  <ExaminationHeader background="dark" />
  <ExaminationRouter />
</template>

<script lang="ts">
import ExaminationHeader from '@/components/Commons/Examination/ExaminationHeader.vue';
import { defineComponent } from 'vue';
import ExaminationRouter from '@/components/Commons/Examination/ExaminationRouter.vue';

export default defineComponent({
  name: 'SharedExamination',
  components: { ExaminationRouter, ExaminationHeader }
});
</script>

<style scoped lang="scss"></style>
